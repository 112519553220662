.footer {
    @apply bg-grey-8;
    &-container {
        @apply flex flex-col relative z-0;
        &:before {
            @apply content-[''] absolute z-0 inset-y-0 left-0 w-1/2 bg-white hidden lg:block;
        }
        :global {
            .mobile-only {
                @apply block lg:hidden #{!important};
            }
        }
    }
    &.customer {
        :global {
            .hidden-guest {
                @apply block;
            }
            .hidden-login {
                @apply hidden;
            }
        }
    }
    &.guest {
        :global {
            .hidden-guest {
                @apply hidden;
            }
            .hidden-login {
                @apply block;
            }
        }
    }
    :global {
        .footer-links {
            @apply lg:flex;
        }
        .footer-contact-links {
            @apply text-center lg:text-left lg:my-3.5;
            li {
                @apply mb-[0.9375rem] leading-[0];
                a {
                    @apply inline-flex items-center gap-2.5 text-xs leading-[0.875rem] font-bold text-grey-1 tracking-[0.03125rem] uppercase;
                    span[aria-hidden] + span {
                        @apply whitespace-nowrap;
                    }
                }
            }
        }
        .footer-sm-links {
            @apply flex items-center justify-center gap-[1.875rem] mt-[1.625rem] lg:justify-start lg:mt-[2.875rem] lg:mb-6;
        }
        .footer-icon-links {
            @apply flex justify-between items-center py-[1.125rem] mx-auto max-w-[18.125rem] lg:justify-start lg:items-start lg:py-0 lg:mr-0 lg:flex-col lg:gap-[2.9375rem] lg:mt-[0.1875rem] lg:ml-auto lg:min-w-[11.9375rem] lg:max-w-[11.9375rem];
            li {
                @apply flex flex-col justify-center items-center gap-4 w-auto lg:w-full lg:flex-row lg:justify-start;
                a {
                    @apply flex flex-col justify-center items-center gap-4 w-auto lg:w-full lg:flex-row lg:justify-start;
                }
                span {
                    @apply text-xs leading-[0.875rem] uppercase tracking-[0.03125rem] font-bold text-grey-1 lg:text-blue-1;
                    &[aria-hidden="true"] {
                        @apply flex items-center justify-center w-[3.9375rem] h-[3.9375rem] bg-[#2174A3] rounded-full lg:w-[3.125rem] lg:h-[3.125rem] lg:bg-grey-1;
                    }
                    .desktop-only {
                        @apply hidden lg:inline #{!important};
                    }
                }
            }
        }
        .tabs {
            @apply border-b border-solid border-grey-7 lg:border-0;
        }
        .tab-header {
            @apply flex justify-between gap-4 pt-[1.375rem] pb-5 pl-[1.875rem] pr-9 lg:px-0 lg:py-0 lg:pointer-events-none;
            &:after {
                background-image: url('data:image/svg+xml,<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.97754 6.808L11.8024 2.136C12.0659 1.864 12.0659 1.448 11.8024 1.192C11.5389 0.936 11.0943 0.936 10.8308 1.192L6.5 5.4L2.16916 1.192C1.88922 0.936 1.46108 0.936 1.1976 1.192C0.934132 1.448 0.934132 1.864 1.1976 2.136L6.00599 6.808C6.28593 7.064 6.71407 7.064 6.97754 6.808Z" fill="%23333333" stroke="%23333333" stroke-width="0.5"/></svg>');
                @apply content-[''] block w-[0.8125rem] h-2 transform transition-all duration-200 ease-in-out lg:hidden;
            }
        }
        .tab-radio {
            @apply h-full w-full lg:w-auto cursor-pointer opacity-0 z-1 absolute lg:invisible;
            &:checked {
                ~ .tab-header {
                    &:after {
                        @apply -rotate-180;
                    }
                }
                ~ .tab-content {
                    @apply max-h-screen;
                }
            }
        }
        .tab-content {
            @apply overflow-hidden max-h-0 relative z-2 px-[1.875rem] lg:px-0 lg:max-h-screen;
            ul {
                @apply flex flex-col gap-[0.8125rem] pb-5 lg:pb-0;
                li {
                    a, span {
                        @apply text-sm leading-6 capitalize text-secondary-2 no-underline hover:underline;
                    }
                    span {
                        @apply cursor-pointer;
                    }
                }
            }
        }
    }
}
.footer-links {
    @apply relative border-t border-solid border-grey-7 lg:border-0;
}
.footer-copyright-block, .footer-sm-block {
    @apply relative z-1;
}
.footer-copyright-block {
    :global {
        div[class *= "row_contained"] {
            @apply max-w-full;
        }
        .copyright-content {
            @apply max-w-[90rem] mx-auto pt-[1.1875rem] px-[1.875rem] lg:pt-8 lg:pr-14 lg:pl-6 lg:pb-2 xl:pt-[2.3125rem] xl:pr-20 xl:pl-[3.0625rem] text-xs leading-[1.125rem] lg:-tracking-[0.015625rem];
            p {
                @apply mb-[0.4375rem] lg:mb-7 xl:mb-9;
                &.copyright-text {
                    @apply text-[0.625rem] leading-[0.9375rem] font-bold mb-[1.875rem] lg:text-xs lg:leading-[1.125rem] lg:font-medium lg:mb-7 xl:mb-9;
                }
            }
        }
    }
}
.footer-sm-block {
    @apply bg-white pt-[2.3125rem] px-[1.875rem] pb-7 lg:p-0 lg:bg-transparent;
}
.grid-rows {
    @apply relative z-1 max-w-[90rem] mx-auto w-full lg:flex;
}
.grid-left {
    @apply lg:w-2/5 lg:max-w-[32.375rem] lg:px-6 lg:pt-10 lg:pb-5 xl:pl-20 xl:pr-10 xl:pt-[3.375rem];
    :global {
        .desktop-only {
            @apply hidden lg:block #{!important};
        }
    }
}
.grid-right {
    @apply bg-grey-8 lg:flex-grow lg:px-6 lg:pt-10 lg:pb-5 xl:pl-[4.3125rem] xl:pr-[3.1875rem] xl:pt-[3.375rem] xl:pb-10;
    :global {
        div[class *= "columnGroup_root"] {
            @apply md:flex-wrap lg:flex-nowrap lg:gap-2.5;
        }
        .pagebuilder-column {
            @apply md:w-full lg:w-[22%] #{!important};
            h3 {
                @apply font-primary text-secondary-2 text-xs leading-[0.875rem] tracking-[0.03125rem] font-bold uppercase lg:mb-[1.375rem];
            }
            &:last-child {
                @apply md:w-full lg:w-[31%] #{!important};
            }
        }
    }
}

div.newsletter-form {
    @apply bg-white pt-[1.375rem] pb-[0.8125rem] px-[1.1875rem] lg:p-0 lg:bg-transparent;
    .newsletter-form-heading {
        @apply text-2xl leading-[1.9375rem] font-bold tracking-normal capitalize text-blue-1 lg:mb-2.5;
    }
    p {
        @apply text-xs leading-[1.125rem] -tracking-[0.015625rem] lg:tracking-normal lg:text-sm lg:leading-6 text-grey-1;
    }
    :global {
        form.hs-form {
            @apply mt-[0.8125rem] mb-[0.9375rem] lg:my-10;
        }
    }
    .footer-hs-wrapper {
        @apply min-h-[1.8125rem];
    }
    form {
        @apply w-full relative;
        :global {
            .focus-visible {
                @apply outline outline-offset-0;
            }
            .hs-input {
                @apply appearance-none bg-transparent border-0 border-b border-solid border-blue-1 py-1.5 focus:shadow-none focus:outline-none;
                max-width: calc(100% - 9.875rem);
                @screen lg {
                    max-width: calc(100% - 10.5rem);
                }
                &::placeholder {
                    @apply text-grey-9;
                }
                &:focus {
                    &::placeholder {
                        @apply text-grey-9;
                    }
                }
                &.error {
                    @apply border-red;
                    &::placeholder {
                        @apply text-grey-2;
                    }
                    &:focus {
                        @apply border-red;
                        &::placeholder {
                            @apply text-grey-2;
                        }
                    }
                }
            }
            .hs-submit {
                @apply absolute top-0 right-0 z-0;
            }
            input[type="submit"] {
                @apply cursor-pointer no-underline m-0 py-0 px-[1.5625rem] lg:px-[2.0625rem] rounded-[3.125rem] text-sm leading-[1.8125rem] font-primary font-bold tracking-[0.03125rem] outline-none border-0 bg-blue-1 text-white focus:shadow-none transform transition-all duration-200 uppercase;
                &.disabled, &[disabled] {
                    @apply border-0 bg-grey-7 text-white opacity-100 pointer-events-none;
                    &:active,
                    &:focus {
                        @apply bg-grey-7 text-white;
                    }
                }
                &:active,
                &:focus {
                    @apply border-0 bg-blue-1 text-white;
                }
                &:hover {
                    @apply border-0 bg-hover text-white transform transition-all duration-200;
                }
            }
            .hs_error_rollup .hs-main-font-element {
                @apply hidden;
            }
            .hs-error-msg {
                @apply w-full text-grey-1 text-xs leading-[1.125rem] -tracking-[0.015625rem] inline-flex gap-2.5;
                &:before {
                    background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" fill="%23E2204F" stroke="%23E2204F" stroke-width="0.7"/><path d="M6.31724 3.75816L6.42662 8.16962H7.57506L7.68444 3.75816H6.31724ZM7.00084 10.4483C7.41555 10.4483 7.74367 10.1338 7.74367 9.73277C7.74367 9.33173 7.41555 9.01727 7.00084 9.01727C6.58613 9.01727 6.258 9.33173 6.258 9.73277C6.258 10.1338 6.58613 10.4483 7.00084 10.4483Z" fill="white"/><path d="M6.3171 3.37637H5.92571L5.9354 3.76766L6.04478 8.17911L6.05401 8.55147H6.42648H7.57492H7.94739L7.95662 8.17911L8.06599 3.76766L8.07569 3.37637H7.6843H6.3171ZM7.0007 10.8301C7.6121 10.8301 8.12536 10.3586 8.12536 9.7328C8.12536 9.10697 7.6121 8.63549 7.0007 8.63549C6.3893 8.63549 5.87604 9.10697 5.87604 9.7328C5.87604 10.3586 6.3893 10.8301 7.0007 10.8301Z" fill="white" stroke="%23E2204F" stroke-width="0.7"/></svg>');
                    @apply content-[''] bg-no-repeat bg-contain w-3 h-3 mt-[0.1875rem] min-w-[0.75rem];
                }
            }
            label[id*="label-email"] {
                @apply sr-only;
            }
        }
    }
}

.checkout-footer-container {
    :global {
        .footer-container {
            > div[class*="columnGroup_root"] {
                @apply w-full max-w-[90rem] mx-auto pt-[1.125rem] pb-5 pl-[1.0625rem] pr-6 lg:py-[1.0625rem] lg:px-5 xl:pl-[2.625rem] xl:pr-[6.1875rem];
                > div[class*="columnGroup_root"] {
                    @apply flex-wrap lg:flex-nowrap;
                }
            }
            .pagebuilder-column {
                @apply basis-full lg:basis-auto;
                @apply w-auto #{!important};
                &:nth-child(3) {
                    @apply order-first lg:order-none lg:ml-auto;
                }
                &:nth-child(4) {
                    @apply order-first pl-2.5 lg:pl-0 lg:order-none;
                }
                figure {
                    @apply max-w-[4.8125rem] mx-auto block lg:max-w-none lg:mx-0;
                }
            }
            .trademark-text {
                @apply text-center ml-[0.5625rem] mt-[0.8125rem] mb-2.5 text-xs leading-[1.125rem] text-grey-1 font-medium -tracking-[0.015625rem] lg:mt-1 lg:text-left lg:mb-0 lg:ml-0;
            }
            .checkout-footer-links {
                @apply max-w-sm mx-auto flex items-center justify-between lg:max-w-none lg:mr-0 lg:justify-start lg:ml-5 xl:ml-8 2xl:ml-[4.125rem] lg:gap-x-5 xl:gap-x-8 2xl:gap-x-[4.125rem];
                li {
                    a {
                        @apply inline-flex items-center text-xs leading-[1.125rem] text-grey-1 font-medium -tracking-[0.015625rem] underline lg:no-underline relative;
                        &:after {
                            @apply hidden lg:block content-[''] absolute bottom-[0.1875rem] inset-x-0 border-b border-solid border-current;
                        }
                        &:hover {
                            &:after {
                                @apply border-transparent;
                            }
                        }
                        .desktop-only {
                            @apply lg:ml-1;
                        }
                    }
                    &.mobile-only {
                        @apply block lg:hidden #{!important};
                    }
                }
            }
            .checkout-footer-contact-links {
                @apply flex flex-col justify-center items-center lg:mt-1 lg:flex-row lg:justify-start lg:gap-x-4 xl:gap-x-7 2xl:gap-x-[2.625rem] lg:mr-4 xl:mr-7 2xl:mr-[2.125rem];
                li {
                    @apply ml-2.5 mb-[0.5625rem] lg:ml-0 lg:mb-0;
                    a {
                        @apply inline-flex items-center gap-x-3 text-xs leading-[0.875rem] uppercase tracking-[0.03125rem] text-grey-1 font-bold;
                    }
                    &.chat-link {
                        @apply ml-2.5 mb-[1.0625rem] lg:ml-0 lg:mb-0;
                        a {
                            @apply gap-x-[0.6875rem];
                        }
                    }
                }
            }
        }
    }
}
